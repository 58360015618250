import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../components/common/Preloader";

const Contact = ({localData}) => {
  const base_Url = process.env.REACT_APP_BASE_URL;
  const [sectionBg, setSectionBg] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localData && localData.home_banners && localData.home_banners.length > 0) {
      setSectionBg(localData);
      setLoading(false);
    }
  }, [localData]);

  // useEffect(() => {
  //   const adminInfoData = JSON.parse(localStorage.getItem("admin-info"));

  //   setLoading(false);
  //   setSectionBg(adminInfoData[0]);
  // }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="contact"
          style={{
            backgroundImage: `url(${base_Url}/images/${sectionBg.colleagues_bg_image})`,
          }}
        >
          <div className="contact-scroll">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.411314726089!2d90.40712851434951!3d23.803968592646125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7d95c61b819%3A0x13de197600ae7aef!2sInspace%20Atelier!5e0!3m2!1sen!2sus!4v1680082779646!5m2!1sen!2sus"
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      style={{ border: "0px;" }}
                    />
                    <div className="contactInfo">
                      <p className="brand">{sectionBg.brand}</p>
                      <p className="address">{sectionBg.address}</p>
                      <div className="contact_link">
                        <p className="phone">
                          Phone:
                          <Link to={`callto:${sectionBg.phone}`}>
                            {sectionBg.phone}
                          </Link>
                        </p>
                        <p className="email">
                          Email:
                          <Link to={`mailto:${sectionBg.email}`}>
                            {sectionBg.email}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
             
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;
