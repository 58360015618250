import Isotope from "isotope-layout";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Preloader from "../components/common/Preloader";
import PeopleContent from "../components/people/PeopleContent";
import PeopleTabs from "../components/people/PeopleTabs";
import "./../assets/styles/isotop.css";

const People = ({ localData }) => {
  const base_Url = process.env.REACT_APP_BASE_URL;
  const [sectionBg, setSectionBg] = useState({});
  const [category, setCategory] = useState([]);
  const [dataCat, setDataCat] = useState([]);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const isotopeInstance = useRef(null);
  const peopleContentRef = useRef(null);

  useEffect(() => {
    if (
      localData &&
      localData.home_banners &&
      localData.home_banners.length > 0
    ) {
      setSectionBg(localData);
      setLoading(false);
    }
  }, [localData]);

  const fetchInitialData = useCallback(async () => {
    try {
      const allPeopleData = JSON.parse(localStorage.getItem("all-people-data"));
      const allPeopleCat = JSON.parse(localStorage.getItem("all-people-cat"));

      setDataCat(allPeopleCat);
      setCategory(allPeopleData);
      setFilter(allPeopleData);
    } catch (error) {
      // console.log(error);
    }
  }, [base_Url, localData]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);


  useEffect(() => {
    if (category.length > 0 && peopleContentRef.current) {
      isotopeInstance.current = new Isotope(peopleContentRef.current, {
        itemSelector: ".people-item",
        layoutMode: "fitRows"
      });
    }
    // Cleanup Isotope on component unmount
    return () => {
      if (isotopeInstance.current) {
        isotopeInstance.current.destroy();
      }
    };
  }, [category]);

  const handleTabChange = (filterValue) => {
    if (isotopeInstance.current) {
      isotopeInstance.current.arrange({ filter: filterValue });
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="people"
          style={{
            backgroundImage: `url(${base_Url}/images/${sectionBg.people_bg_image})`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <PeopleTabs tabs={dataCat} onTabChange={handleTabChange} />
              </div>
              <div className="col-lg-9">
                <div ref={peopleContentRef} className="people-content">
                  <PeopleContent categoryData={category} base_Url={base_Url} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default People;
