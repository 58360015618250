import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingImage from "../../assets/images/loading_page.png";

const PeopleContent = ({ categoryData, base_Url }) => {
  const [selectedPerson, setSelectedPerson] = useState({});

  const openModal = (person) => {
    setSelectedPerson(person);
  };

  const closeModal = () => {
    setSelectedPerson({});
  };

  if (!Array.isArray(categoryData)) {
    // Handle the case when categoryData is not an array
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="alert alert-primary" role="alert">
              No data available.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="people-scroll">
        <div className="row">
          {categoryData.length > 0 ? (
            categoryData.map((item) => (
              <div className={`col-xl-3 col-lg-4 col-md-6 col-12 mb-4 people-item cat-${item.cat_id}`} key={item.id} >
                <div className="tabs-content">
                  <figure>
                    <LazyLoadImage
                      src={`${base_Url}/images/${item.image}`}
                      placeholderSrc={LoadingImage}
                      alt={item.image}
                      effect="blur"
                      loading="lazy"
                      className="img-fluid"
                      wrapperProps={{
                        style: { transitionDelay: "1s" }
                      }}
                    />
                  </figure>
                  <div className="content">
                    <h2
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      onClick={() => openModal(item)}
                    >
                      {item.name}
                    </h2>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-lg-12 text-center">
              <div className="alert alert-primary" role="alert">
                No data available.
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="modal fade modal-lg"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div
            className="modal-content rounded-0"
            style={{ background: "rgba(255, 255, 255, .9)" }}
          >
            <div className="modal-body position-relative">
              <button
                type="button"
                className="btn-close position-absolute top-0 end-0 rounded mt-2 me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
              <div className="card border-0" style={{ background: "transparent" }}>
                <div className="row g-0">
                  <div className="col-md-5">
                    <LazyLoadImage
                      src={`${base_Url}/images/${selectedPerson.image}`}
                      placeholderSrc={LoadingImage}
                      alt={selectedPerson.image}
                      style={{ objectFit: "cover" }}
                      effect="blur"
                      loading="lazy"
                      className="img-fluid"
                      wrapperProps={{
                        // If you need to, you can tweak the effect transition using the wrapper style.
                        style: { transitionDelay: "1s" }
                      }}
                    />
                  </div>
                  <div className="col-md-7">
                    <div className="card-body">
                      <h4 className="card-title">{selectedPerson.name}</h4>
                      <br />
                      <p className="card-text">
                        {selectedPerson.description && (
                          <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                            {selectedPerson.description
                              .split("|")
                              .map((item, index) => (
                                <li key={index}>
                                  <p>{item}</p>
                                </li>
                              ))}
                          </ul>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PeopleContent;
