
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import PlaceholderImage from "./../../assets/images/loading_page.webp";

const WorkContent = ({ categoryData, base_Url }) => {
  if (!Array.isArray(categoryData)) {
    return <p>No data available.</p>;
  }

  return (
    <>
      <div className="work-scroll">
        <div className="row">
          {categoryData.map((item) => (
            <div className={`col-lg-3 mb-4 work-item cat-${item.cat_id}`} key={item.id}>
              <Link to={`/work-details/${item.id}`}>
                <div className="tabs-content">
                  <LazyLoadImage
                    src={`${base_Url}/images/${item.image_01}`}
                    placeholderSrc={PlaceholderImage}
                    alt={item.image_01}
                    effect="blur"
                    loading="lazy"
                    className="img-fluid"
                    wrapperProps={{
                      // If you need to, you can tweak the effect transition using the wrapper style.
                      style: { transitionDelay: "1s" }
                    }}
                  />
                </div>
                <div className="content">
                  <p>{item.name}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WorkContent;
