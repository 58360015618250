import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import Navbar from '../Navbar/Navbar';
import SocialIcon from '../SocialIcon/SocialIcon';

const Layout = ({localData}) => {
    return (
        <>
            <Header localData={localData}/>
            <Outlet/>
            <SocialIcon localData={localData}/>
            <Navbar/>
        </>
    );
};

export default Layout;