import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "./../components/common/Preloader";

const Colleagues = ({localData}) => {
  const base_Url = process.env.REACT_APP_BASE_URL;
  const [sectionBg, setSectionBg] = useState({});
  const [careerData, setCareerData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localData && localData.home_banners && localData.home_banners.length > 0) {
      setSectionBg(localData);
      setLoading(false);
    }
  }, [localData]);


  useEffect(() => {
    // const adminInfoData = JSON.parse(localStorage.getItem("admin-info"));
    const colleaguesData = JSON.parse(localStorage.getItem("colleagues-data"));

    // setLoading(false);
    // setSectionBg(adminInfoData[0]);
    setCareerData(colleaguesData);
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="colleagues"
          style={{
            backgroundImage: `url(${base_Url}/images/${sectionBg.colleagues_bg_image})`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="collegue-scroll">
                  {careerData.length > 0 &&
                    careerData.map((item) => (
                      <div key={item.id} className="colleagues-details-data">
                        <h4 className="title">{item.title}</h4>
                        <h6 className="vacancy">Vacancy: {item.vacancy}</h6>
                        <p className="section-title">Job Context:</p>
                        <p className="section-details">{item.context}</p>
                        <p className="section-title">Job Responsibilities:</p>
                        <p className="section-details">
                          {/* {item.responsibilities} */}
                          <ul>
                        {item.responsibilities
                          .split("|")
                          .map((item) => (
                            <li>
                              {item}
                            </li>
                          ))}
                      </ul>
                        </p>
                        <p className="section-title">Education:</p>
                        <p className="section-details">{item.education}</p>
                        <p className="section-title">Requirements:</p>
                        <p className="section-details">
                          <ul>
                            {item.requirements.split("|").map((item) => (
                              <li>
                                {item}
                              </li>
                            ))}
                          </ul>
                        </p>
                        <p className="section-title">Salary:</p>
                        <p className="section-details">{item.salary}</p>
                        <p className="section-title">Mail your CV:</p>
                        <p className="section-details">
                          <Link
                            to="mailto:inspaceatelier@gmail.com"
                            className="apply-btn"
                          >
                            {item.others}
                          </Link>
                        </p>
                        <p className="section-details">or</p>
                        <Link
                          to={item.g_form}
                          target="_blank"
                          className="apply-btn"
                        >
                          Apply by Google Form
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Colleagues;
