import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import Preloader from "../components/common/Preloader";
import LoadingImage from "./../assets/images/loading_page.png";

const Practice = ({localData}) => {
  const base_Url = process.env.REACT_APP_BASE_URL;
  const [sectionBg, setSectionBg] = useState({});
  const [peopleData, setPeopleData] = useState({});
  const [aboutData, setAboutData] = useState({});
  const [officeData, setOfficeData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localData && localData.home_banners && localData.home_banners.length > 0) {
      setSectionBg(localData);
      setLoading(false);
    }
  }, [localData]);

  useEffect(() => {
    // const adminInfoData = JSON.parse(localStorage.getItem("admin-info"));
    const aboutData = JSON.parse(localStorage.getItem("about-data"));
    const officeData = JSON.parse(localStorage.getItem("office-data"));
    const historyData = JSON.parse(localStorage.getItem("history-data"));

    // setLoading(false);
    // setSectionBg(adminInfoData[0]);
    setAboutData(aboutData[0]);
    setOfficeData(officeData[0]);
    setPeopleData(historyData);
  }, []);

  const peopleSlider = {
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    className: "center",
    centerMode: false,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="practise"
          style={{
            backgroundImage: `url(${base_Url}/images/${sectionBg.practice_bg_image})`
          }}
        >
          <div className="container">
            <div className="practise-scroll">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={12} xl={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">About Us</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Office</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">History</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12} xl={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="about">
                          <h4 className="short-description">
                            {aboutData.short_des}
                          </h4>
                          <LazyLoadImage
                            effect="blur"
                            loading="lazy"
                            src={`${base_Url}/images/${aboutData.image}`}
                            alt=""
                            className="img-fluid"
                            wrapperProps={{
                              // If you need to, you can tweak the effect transition using the wrapper style.
                              style: { transitionDelay: "1s" }
                            }}
                          />
                          <p className="full-description">
                            {aboutData.full_des}
                          </p>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="about">
                          <h4 className="short-description">
                            {officeData?.short_des}
                          </h4>
                          <LazyLoadImage
                            effect="blur"
                            loading="lazy"
                            src={`${base_Url}/images/${officeData?.image}`}
                            alt=""
                            className="img-fluid"
                            wrapperProps={{
                              // If you need to, you can tweak the effect transition using the wrapper style.
                              style: { transitionDelay: "1s" }
                            }}
                          />
                          <p className="full-description">
                            {officeData?.full_des}
                          </p>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="office">
                          <Slider {...peopleSlider}>
                            {peopleData.length > 0 &&
                              peopleData.map((data) => (
                                <div
                                  key={data.id}
                                  id={data.id}
                                  className="sliderItem"
                                >
                                  <h4 className="year">{data.year}</h4>
                                  <LazyLoadImage
                                    effect="blur"
                                    loading="lazy"
                                    src={`${base_Url}/images/${data.image}`}
                                    placeholderSrc={LoadingImage}
                                    alt={data.image}
                                    className="img-fluid"
                                    wrapperProps={{
                                      // If you need to, you can tweak the effect transition using the wrapper style.
                                      style: { transitionDelay: "1s" }
                                    }}
                                  />
                                  <h4 className="title">{data.title}</h4>
                                  <p className="desc">{data.full_des}</p>
                                </div>
                              ))}
                          </Slider>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Practice;
