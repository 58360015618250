import React from "react";

const Preloader = () => {
 
  return (
    <>
      <div className="preloader">
        <img
          src={require(`./../../assets/images/loading_page.webp`)}
          className="img-fluid"
          alt=""
        />
      </div>
    </>
  );
};

export default Preloader;
