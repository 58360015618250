import React from "react";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

const SocialIcon = ({localData}) => {
  return (
    <>
      <ul className="social-icon">
        <li>
          <Link to={localData?.facebook} target="_blank">
            <FaFacebookF />
          </Link>
        </li>
        <li>
          <Link to={localData?.instagram} target="_blank">
            <AiOutlineInstagram />
          </Link>
        </li>
        <li>
          <Link to={localData?.linkedin} target="_blank">
            <FaLinkedinIn />
          </Link>
        </li>
        <li>
          <Link to={localData?.youtube} target="_blank">
            <AiFillYoutube />
          </Link>
        </li>
      </ul>
    </>
  );
};

export default SocialIcon;
