import React from "react";

const PeopleTabs = ({ tabs, onTabChange }) => {
  return (
    <div className="people_tabs">
      <button onClick={() => onTabChange("*")}>All</button>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          id={tab.id}
          onClick={() => onTabChange(`.cat-${tab.id}`)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};

export default PeopleTabs;
