import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <div className="footer_navbar">
        <div className="container">
          <div className="row">
            {/* <div className="col-12">
              <table className="table table-bordered">
                <tbody className="bg-transparent">
                  <tr>
                    <td colSpan="1" className="border-0 bg-transparent">
                      <span className="opacity-0 disabled">
                        <NavLink to={""}>Work</NavLink>
                      </span>
                    </td>
                    <td colSpan="1" className="bg-transparent">
                      <NavLink to={"work"}>Work</NavLink>
                    </td>
                    <td colSpan="1" className="border-0 bg-transparent">
                      <span className="opacity-0 disabled">
                        <NavLink to={""}>Practice</NavLink>
                      </span>
                    </td>
                    <td colSpan="1" className="bg-transparent">
                      <NavLink to={"practise"}>Practice</NavLink>
                    </td>
                    <td colSpan="1" className="border-0 bg-transparent">
                      <span className="opacity-0 disabled">
                        <NavLink to={"practise"}>Practice</NavLink>
                      </span>
                    </td>
                    <td colSpan="1" className="bg-transparent">
                      <NavLink to={"contact"}>Contact</NavLink>
                    </td>
                    <td colSpan="1" className="border-0 bg-transparent">
                      <span className="opacity-0">Home</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="1"
                      className="bg-transparent disabled opacity-0"
                    >
                      <span>Home</span>
                    </td>
                    <td
                      colSpan="1"
                      className="border-0 bg-transparent disabled opacity-0"
                    >
                      <span>Contact</span>
                    </td>
                    <td colSpan="1" className="bg-transparent">
                      <NavLink to={"people"}>People</NavLink>
                    </td>
                    <td
                      colSpan="1"
                      className="border-0 bg-transparent disabled opacity-0"
                    >
                      <span>People</span>
                    </td>
                    <td colSpan="1" className="bg-transparent">
                      <NavLink to={"Colleagues"}>Future Colleague</NavLink>
                    </td>
                    <td
                      colSpan="1"
                      className="border-0 bg-transparent disabled opacity-0"
                    >
                      <span>Contact</span>
                    </td>
                    <td
                      colSpan="1"
                      className="bg-transparent opacity-0 disabled"
                    >
                      <span>Contact</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
            <div className="col-12">
              <div class="grid">
                <div></div>
                <div>
                  <NavLink to={"work"}>Work</NavLink>
                </div>
                <div></div>
                <div>
                  <NavLink to={"practise"}>Practice</NavLink>
                </div>
                <div></div>
                <div>
                  <NavLink to={"contact"}>Contact</NavLink>
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div>
                  <NavLink to={"people"}>People</NavLink>
                </div>
                <div></div>
                <div>
                  <NavLink to={"Colleagues"}>Future Colleague</NavLink>
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
